<script>
import {logoHelper} from "@/helpers/logo-helper";

export default {
  computed: {
    logoHelper() {
      return logoHelper
    }
  },

  created() {
    document.body.classList.remove("auth-body-bg");
  }

};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </a>
    </div>
    <div class="my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="text-center">
              <div class="mb-5">
                <a href="/">
                  <img :src="logoHelper.logo()" alt="logo" height="64" />
                </a>
              </div>

              <div class="row justify-content-center">
                <div class="col-sm-4">
                  <div class="maintenance-img">
                    <img
                      src="@/assets/images/maintenance-bg.png"
                      alt
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
              <h3 class="mt-5">Brak uprawnień do funkcji beta!</h3>
              <a href="//manager.ec-at.com">Kliknij tutaj, aby wejść na wersję dostępną dla każdego</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>